'use client';

import usePage from '@/store/page/page';
import ChartbeatTracker from '@/components/atoms/ChartbeatTracker/ChartbeatTracker';
import { CHARTBEAT_SRC, CHARTBEAT_VIDEO_SRC } from '@/components/atoms/ChartbeatTracker/constants';

// Formatting of non-one-word section names eg. Whats'on
const formatSection = (secondarySection: string, sectionPrimary: string, sectionSecondary: string) => {
  const formattedPrimarySection = sectionPrimary.toLowerCase().replace(/'/g, '').replace(/\s+/g, '-');

  const formattedSecondarySection = sectionSecondary.toLowerCase().replace(/'/g, '').replace(/\s+/g, '-');

  return secondarySection ? `${formattedPrimarySection},${formattedSecondarySection}` : `${formattedPrimarySection}`;
};

const ArticleChartbeat = ({ configData }: { configData: any }) => {
  const { chartbeatUid, domainName, isVideoOnPage } = configData;
  const pageData = usePage();

  const authors = pageData?.state?.server?.authors;
  const authorNames = authors?.map((author) => author.name).join(', ');
  const primarySection = pageData?.state?.server?.sections?.pageSection;
  const secondarySection = pageData?.state?.server?.sections?.pagesecondarySection;

  return (
    <>
      {pageData?.state?.server?.sections && (
        <ChartbeatTracker
          domain={domainName}
          chartbeatUid={chartbeatUid}
          sections={`${formatSection(secondarySection, primarySection, secondarySection)}`}
          authors={authorNames}
          title={''}
          path={''}
          chartbeatSrc={isVideoOnPage ? CHARTBEAT_VIDEO_SRC : CHARTBEAT_SRC}
        />
      )}
    </>
  );
};

export default ArticleChartbeat;
